<template>
  <section id="operproccess__coming">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        page_type="cashless"
        @date_range="updateDateRange"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="doc_table_filter"
          table_name="cashless-table"
          :total="total"
          :selected_sum="selected_sum"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <documents-table
          :fields="fields"
          :items_total="items_total"
          :items="document_list"
          :table_is_busy="tableIsBusy"
          :get_select_all="getSelectAll"
          :get_selected_arr="getSelectedArr"
          :set_select_all="SET_SELECT_ALL"
          :set_selected="SET_SELECTED"
          page_type="cashless"
          @select="setSelected"
        />
      </div>
      <table-navbar
        :items="getSelectedArr"
        :clear_selected="clearSelected"
        items_name="documents"
        doc_type="outgoing"
        price_mode="retail"
        @remove_items="remove_items"
      />
      <pagination
        :total="items_total"
        table_name="cashless-table"
        @change_pagination="handlerPagination"
      />
    </div>
  </section>
</template>

<script>
  import DocumentsHeader from '../../components/DocumentsHeader'
  import DocumentsTableFilter from '../../components/DocumentsTableFilter'
  import DocumentsTable from '../../components/overhead/DocumentsTable'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
      DocumentsHeader,
      DocumentsTableFilter,
      DocumentsTable,
      Pagination,
      TableNavbar
    },

    apollo: {
      OutgoingHeads: {
        query: require('../../gql/getOutgoingHeads.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null,
            operation: ['cash_sale', 'cashless_sale'],
            filters: {
              suppliers: this.getFilters?.supplier.map((item) => item.id) || [],
              operations: this.getFilters.operation.map((obj) => obj.key) || [],
              statuses: this.calcStatus,
              managers: this.getFilters?.owners.map((item) => item.id) || []
            },
            documentPagination: {
              period: {
                begin: this.data_range[0] ?? null,
                end: this.data_range[1] ?? null
              },
              skip: this.getPagination?.['cashless-table']?.skip,
              take: this.getPagination?.['cashless-table'].take,
              search: this.getPagination?.['cashless-table'].search
            }
          }
        },
        result({ data }) {
          this.SET_SELECTED([])
          this.items_total = data.OutgoingHeads?.total ?? 0
          this.document_list = data.OutgoingHeads?.documents ?? []
          this.total = {
            pur_sum: data?.OutgoingHeads?.pur_sum ?? 0,
            ret_sum: data?.OutgoingHeads?.ret_sum ?? 0,
            nds_sum: data?.OutgoingHeads?.nds_sum ?? 0
          }

          this.tableIsBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        },
        watchLoading(isLoading) {
          this.tableIsBusy = isLoading
        }
      }
    },

    data: function () {
      return {
        selected_sum: null,
        total: {
          ret_sum: 0,
          pur_sum: 0,
          nds_sum: 0
        },
        tableIsBusy: true,
        file: null,
        title: this.$route.meta.title,
        filter: null,
        filter_date_range: null,
        document_list: [],
        table_fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center id',
            thStyle: 'min-width: 48px'
          },
          {
            key: 'date',
            label: 'Дата',
            thStyle: 'min-width: 131px',
            sortable: true
          },
          {
            key: 'supplier',
            label: 'Поставщик',
            thStyle: 'min-width: 300px',
            sortable: true
          },
          {
            key: 'retailSum',
            label: 'Итого по док-ту (₽)',
            class: 'center',
            sortable: true,
            thStyle: 'min-width: 192px'
          },
          {
            key: 'operation',
            label: 'Операция док-та',
            class: 'center',
            thStyle: 'min-width: 168px'
          },
          {
            key: 'fixed',
            label: 'Статус',
            thStyle: 'min-width: 140px',
            class: 'center'
          },
          {
            key: 'owner',
            label: 'Менеджер',
            thStyle: 'min-width: 188px',
            class: 'center'
          },
          {
            key: 'comment',
            label: 'Комментарий',
            thStyle: 'min-width: 200px'
          }
        ],
        items_total: 0,
        data_range: []
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination',
        getFilters: 'cashless/getFilters',
        getSelectedArr: 'cashless/getSelectedArr',
        getSelectAll: 'cashless/getSelectAll'
      }),
      fields() {
        return this.table_fields
      },
      calcStatus() {
        const status = this.getFilters?.status.map((item) => item.id)
        if (status.length === 0 || status.length === 2) {
          return null
        }
        return status[0] === 'fixed'
      }
    },
    watch: {
      '$route.path'() {
        this.title = this.$route.meta.title
      }
    },

    mounted() {},

    updated() {
      this.page_type = this.$route.meta.type
    },

    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      ...mapMutations({
        SET_SELECTED: 'cashless/SET_SELECTED',
        SET_SELECT_ALL: 'cashless/SET_SELECT_ALL'
      }),

      setSelected() {
        if (!this.getSelectedArr.length) this.selected_sum = null
        else {
          let selected_docs = this.document_list.filter((obj) => this.getSelectedArr.some((item) => item === obj.id))
          this.selected_sum = selected_docs.reduce(
            (acc, doc) => {
              return {
                sum: acc.sum + (+doc.sum ?? 0),
                retailSum: acc.retailSum + (+doc?.retailSum ?? 0),
                nds: acc.nds + (+doc?.nds_sum ?? 0)
              }
            },
            { sum: 0, retailSum: 0, nds: 0 }
          )
        }
      },

      clearSelected() {
        this.SET_SELECTED([])
        this.SET_SELECT_ALL(false)
      },

      updateDateRange(date) {
        this.data_range = date
        this.$apollo.queries.OutgoingHeads.refresh()
      },

      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['cashless-table']: {
            ...this.getPagination?.['cashless-table'],
            search
          }
        })
      },

      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['cashless-table']: {
              ...this.getPagination?.['cashless-table'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['cashless-table']: {
              ...this.getPagination?.['cashless-table'],
              take
            }
          })
        }
      },

      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['cashless-table']: {
            ...this.getPagination?.['cashless-table'],
            search: ''
          }
        })
      },

      async remove_items(items) {
        this.tableIsBusy = true

        await this.$apollo.mutate({
          mutation: require(`@/views/operational-processes/gql/RemoveOutgoingDocument.graphql`),
          variables: {
            ids: items
          }
        })

        this.SET_SELECTED([])

        await this.$apollo.queries.OutgoingHeads.refetch()

        this.tableIsBusy = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  #operproccess__coming {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
